import filter from 'lodash/filter'
import { LOCATION_CHANGE } from 'connected-react-router'

import {
  ADDRESS_INPUT_FIELD_UPDATE,
  ADD_ADDRESS_FORM_VALIDATION_ERROR,
  LOAD_GLOBAL_DATA
} from 'constants/action_types'
import * as type from 'constants/action_types/accounts'
import * as apiKeysTypes from 'constants/action_types/api_keys'
import { SET_GATED_FEATURE } from 'constants/action_types/accounts/featureLimits'
import { MWS_SYNC_STATUS, PPC_SYNC_STATUS } from 'constants/account'

import { updateInArray, updateAllInArray, replaceAtIndex } from 'helpers/arrays'
import { getShowState } from 'helpers/core'

export const initialState = {
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    users: ''
  },

  profileValidations: {
    firstName: '',
    lastName: '',
    email: '',
    newPassword: '',
    users: ''
  },

  authy: {
    hasAuthy: false,
    code: '',
    phoneNumber: '',
    isLoading: false,
    completed: false
  },

  billing: {
    settings: {},
    invoices: [],
    nextInvoice: null,
    periodEnd: null,
    checkAll: null,
    numberChecked: null,
    activeInvoice: null,
    page: 1
  },

  stripeSubscriptionInfo: {
    onTrial: false,
    trialEndDate: null,
    samePeriodBundle: false
  },

  billingAddress: {
    firstName: '',
    lastName: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: ''
  },

  billingAddressValidations: {
    firstName: '',
    lastName: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: ''
  },

  churnFunnel: {
    answers: {
      reason: null
    }
  },

  showAddUserModal: false,
  showAuthyModal: false,
  showUpgradeModal: false,

  page: {
    isLoading: false,
    completed: false
  },

  stripe: {
    isLoading: false,
    completed: false
  },

  saveAccount: {
    isLoading: false,
    completed: false
  },

  saveProfile: {
    isLoading: false,
    completed: false
  },

  savePassword: {
    isLoading: false,
    completed: false
  },

  cancel: {
    isLoading: false,
    completed: false
  },

  pause: {
    isLoading: false,
    completed: false
  },

  reactivate: {
    isLoading: false,
    completed: false
  },

  extensionReactivation: {
    isLoading: false,
    completed: false
  },

  nicheEmail: {
    isLoading: false,
    completed: false
  },

  invoices: {
    isLoading: false,
    completed: false
  },

  viewInvoice: {
    isLoading: false,
    completed: false
  },

  downloadInvoice: {
    isLoading: false,
    completed: false
  },

  emailInvoice: {
    isLoading: false,
    completed: false
  },

  uploadingPhoto: {
    isLoading: false,
    completed: false
  },

  deletingPhoto: {
    isLoading: false,
    completed: false
  },

  inviteUsers: {
    isLoading: false,
    completed: false,
    email: null
  },

  revokeUser: {
    isLoading: false,
    completed: false,
    email: null
  },

  creditCard: {
    isLoading: false,
    completed: false
  },

  unbindAlipayLoading: false,
  bindAlipayLoading: false,

  downgradePlans: {
    isLoading: false,
    completed: false
  },

  mwsSync: {
    createSuccess: null,
    isLoading: false,
    isStatusLoading: false,
    availableMarketplaces: {
      eu: [],
      na: [],
      fe: []
    },
    data: {
      auth_token: '',
      spapi_oauth_code: '',
      label: '',
      merchant_id: '',
      primary_marketplace_id: '',
      sc_email: '',
      region: null // local only
    },
    status: {
      overall_percentage: undefined,
      amazon_seller_accounts: []
      /* structure for ^ {
        "id": 8,
        "mws_sync": 0,
        "mws_product": 100,
        "mws_order": 0,
        "mws_finance": 0.410396716826265
      } */
    }
  },

  launchDowngradePlan: null,

  ui: {
    amazonSellerAccounts: {
      editingAccount: null, // the account ID for the account we're editing
      modalOpen: null, // one of AMAZON_SELLER_ACCOUNT_MODALS or null if none open
      ppcSyncStatus: PPC_SYNC_STATUS.initial
    },
    mwsSync: {
      activeModal: null, // one of AMAZON_MWS_SYNC_MODALS
      statusBarVisible: false,
      previousModal: null,
      isUpgrade: false,
      syncModal: {
        isVisible: false
      },
      mwsSyncStatus: MWS_SYNC_STATUS.initial,
      ppcSyncStatus: PPC_SYNC_STATUS.initial,
      ppcSyncWasSkipped: false
    }
  },

  gatedFeature: {
    limit_type: null,
    limit: null,
    scope: null
  },

  apiKeys: {
    keys: [],
    createdKey: {
      name: '',
      raw_key: ''
    },
    requestCount: 0,
    billingPeriodStart: '',
    limit: 0,
    isLoading: false,
    isCreating: false,
    completed: false,
    isRevoking: false,
    generateModalOpen: false
  }
}

const addUserInfo = (state, { payload }) => {
  if (!payload.user) return state

  const {
    first_name: firstName,
    last_name: lastName,
    email,
    is_amazon_seller: isAmazonSeller
  } = payload.user

  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      firstName,
      lastName,
      email,
      isAmazonSeller
    }
  }
}

const closePpcModal = state => {
  return {
    ...state,
    ui: {
      ...state.ui,
      amazonSellerAccounts: {
        ...state.ui.amazonSellerAccounts,
        editingAccount: null,
        modalOpen: null
      }
    }
  }
}

const closeMwsModal = state => {
  return {
    ...state,
    mwsSync: {
      ...state.mwsSync,
      data: initialState.mwsSync.data
    },
    ui: {
      ...state.ui,
      mwsSync: initialState.ui.mwsSync
    }
  }
}

function checkForActiveMWSAccount(state, { payload }) {
  // this shouldn't happen, but just to be safe
  if (!payload) {
    return state
  }

  const { amazonSellerAccounts } = payload

  // if the user has some seller accounts - there's nothing for us to do
  if (Array.isArray(amazonSellerAccounts) && amazonSellerAccounts.length > 0) {
    // if any account has display sync status true - display the status bar
    if (amazonSellerAccounts.some(account => account.display_sync_status)) {
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            statusBarVisible: true
          }
        }
      }
    }
  }
  return state
}

function account(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_ACCOUNT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_ACCOUNT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.PENDING_CREATING_ACCOUNT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isCreating: true
        }
      }
    case type.COMPLETED_CREATING_ACCOUNT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isCreating: false
        }
      }
    case type.RESET_ACCOUNT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    case type.COMPLETED_LOAD_ACCOUNT:
      return {
        ...state,
        ...action.payload,
        page: {
          isLoading: false,
          completed: true
        }
      }
    case type.COMPLETED_BILLING_ACTION:
      return {
        ...state,
        billing: {
          ...state.billing,
          ...action.payload
        }
      }
    case type.ACCOUNT_INPUT_FIELD_UPDATE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [action.payload.prop]: action.payload.value
        }
      }
    case type.ACCOUNT_DROPDOWN_FIELD_UPDATE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [action.payload.prop]: action.payload.value
        }
      }
    case type.ADD_ACCOUNT_PROFILE_VALIDATION_ERROR:
      const { field, message } = action.payload
      return {
        ...state,
        profileValidations: {
          ...state.profileValidations,
          [field]: message
        }
      }
    case ADDRESS_INPUT_FIELD_UPDATE:
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          [action.payload.prop]: action.payload.value
        }
      }
    case ADD_ADDRESS_FORM_VALIDATION_ERROR: {
      const { field, message } = action.payload
      return {
        ...state,
        billingAddressValidations: {
          ...state.billingAddressValidations,
          [field]: message
        }
      }
    }
    case type.RESET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          oldPassword: initialState.userInfo.oldPassword,
          newPassword: initialState.userInfo.newPassword
        }
      }
    case type.TOGGLE_AUTHY_SIGNUP_MODAL:
      return {
        ...state,
        showAuthyModal: getShowState(state.showAuthyModal, action.payload),
        authy: {
          ...state.authy,
          phoneNumber: ''
        }
      }
    case type.AUTHY_INPUT_FIELD_UPDATE:
      return {
        ...state,
        authy: {
          ...state.authy,
          [action.payload.prop]: action.payload.value
        }
      }
    case type.TOGGLE_CHECK_INVOICE: {
      const newCheckedState = getShowState(action.payload.checked)

      const updatedInvoices = updateInArray(
        state.billing.invoices,
        { id: action.payload.id },
        { checked: newCheckedState }
      )

      const updatedCount = filter(updatedInvoices, {
        checked: true
      }).length

      return {
        ...state,
        billing: {
          ...state.billing,
          invoices: updatedInvoices,
          checkAll: updatedCount === state.billing.invoices.length,
          numberChecked: updatedCount
        }
      }
    }
    case type.TOGGLE_CHECK_ALL_INVOICES: {
      const newCheckedState = getShowState(state.billing.checkAll)

      const updatedInvoices = updateAllInArray(state.billing.invoices, {
        checked: newCheckedState
      })

      const updatedCount = filter(updatedInvoices, {
        checked: true
      }).length

      return {
        ...state,
        billing: {
          ...state.billing,
          invoices: updatedInvoices,
          checkAll: newCheckedState,
          numberChecked: updatedCount
        }
      }
    }
    case type.RESET_CHECKED_INVOICES: {
      const updatedInvoices = updateAllInArray(state.billing.invoices, {
        checked: false
      })

      return {
        ...state,
        billing: {
          ...state.billing,
          invoices: updatedInvoices,
          checkAll: false,
          numberChecked: 0
        }
      }
    }
    case type.INVOICE_INPUT_FIELD_UPDATE:
      return {
        ...state,
        billing: {
          ...state.billing,
          settings: {
            ...state.billing.settings,
            touched: true,
            [action.payload.prop]: action.payload.value
          }
        }
      }
    case type.SET_ACTIVE_INVOICE:
      return {
        ...state,
        billing: {
          ...state.billing,
          activeInvoice: action.payload
        }
      }
    case type.TOGGLE_REVOKE_USER_CONFIRM_MODAL: {
      return {
        ...state,
        showRevokeUserConfirmModal: getShowState(
          state.showRevokeUserConfirmModal
        ),
        revokeUser: {
          ...state.revokeUser,
          email: action.payload
        }
      }
    }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          amazonSellerAccounts: {
            ...state.ui.amazonSellerAccounts,
            editingAccount: action.payload.accountId,
            modalOpen: action.payload.modal,
            ppcSyncStatus:
              action.payload.ppcSyncStatus || PPC_SYNC_STATUS.initial
          }
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_MODAL:
      return closePpcModal(state)
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_SET_PPC_SYNC_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          amazonSellerAccounts: {
            ...state.ui.amazonSellerAccounts,
            ppcSyncStatus: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SHOW_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            activeModal: action.payload.modal,
            previousModal: state.ui.mwsSync.activeModal,
            isUpgrade: action.payload.isUpgrade,
            noHeader: action.payload.noHeader,
            mwsSyncStatus:
              action.payload.mwsSyncStatus || MWS_SYNC_STATUS.initial,
            ppcSyncStatus:
              action.payload.ppcSyncStatus || PPC_SYNC_STATUS.initial
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_CLOSE_MODAL:
      return closeMwsModal(state)
    case type.ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            ppcSyncStatus: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STEP_SKIPPED:
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            ppcSyncWasSkipped: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_REGION:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            region: action.payload,
            primary_marketplace_id: null
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_MARKETPLACE:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            primary_marketplace_id: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_SELLER_ID:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            merchant_id: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_AUTH_TOKEN:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            auth_token: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_ACCOUNT_NAME:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            label: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_SELLER_EMAIL:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            sc_email: action.payload
          }
        }
      }
    case type.ACCOUNT_MWS_SYNC_SET_DATA:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          data: {
            ...state.mwsSync.data,
            ...action.payload
          }
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_PENDING_STATUS:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          isLoading: true,
          createSuccess: null
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          // reset the account incase the user wants to add another one
          data: { ...initialState.mwsSync.data }
        },
        ui: {
          ...state.ui,
          amazonSellerAccounts: {
            ...state.ui.amazonSellerAccounts,
            // Used to associate account with PPC login
            editingAccount: action.payload.id
          }
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE_STATUS:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          createSuccess: action.payload.success,
          isLoading: false
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_ERROR:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          errors: action.payload
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_PENDING_STATUS:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          isStatusLoading: true
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          status: action.payload
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE_STATUS:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          isStatusLoading: false
        },
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            statusBarVisible: action.payload.success // don't show the status bar if it failed to load
          }
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_STATUS_BAR:
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            statusBarVisible: false
          }
        }
      }
    case type.ACCOUNT_AMAZON_SELLER_ACCOUNT_TOGGLE_SYNC_DETAILS_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          mwsSync: {
            ...state.ui.mwsSync,
            syncModal: {
              ...state.ui.mwsSync.syncModal,
              isVisible: !state.ui.mwsSync.syncModal.isVisible
            }
          }
        }
      }
    case LOAD_GLOBAL_DATA: {
      const newState = addUserInfo(state, action)
      return checkForActiveMWSAccount(newState, action)
    }
    case type.ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE:
      return {
        ...state,
        mwsSync: {
          ...state.mwsSync,
          availableMarketplaces: {
            ...state.mwsSync.availableMarketplaces,
            na: action.payload.primary_marketplaces.NA.map(item => ({
              countryCode: item[0],
              name: item[2],
              value: item[1]
            })),
            eu: action.payload.primary_marketplaces.EU.map(item => ({
              countryCode: item[0],
              name: item[2],
              value: item[1]
            })),
            fe: action.payload.primary_marketplaces.FE.map(item => ({
              countryCode: item[0],
              name: item[2],
              value: item[1]
            }))
          }
        }
      }
    case LOCATION_CHANGE:
      if (state?.ui?.mwsSync?.mwsSyncStatus !== 'initial') {
        return state
      }
      const cleanedState = [closeMwsModal, closePpcModal].reduce(
        (state, func) => func(state),
        state
      )
      return cleanedState

    case type.UNBIND_ALIPAY_LOADING:
      return { ...state, unbindAlipayLoading: action.payload }

    case type.BIND_ALIPAY_LOADING:
      return { ...state, bindAlipayLoading: action.payload }

    case SET_GATED_FEATURE:
      return {
        ...state,
        gatedFeature: {
          ...action.payload
        }
      }
    case apiKeysTypes.SET_API_KEYS:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          keys: action.payload.apiKeys,
          requestCount: action.payload.apiRequests.total_count,
          billingPeriodStart: action.payload.apiRequests.start_date,
          limit: action.payload.apiRequests.limit
        }
      }
    case apiKeysTypes.SET_GENERATE_KEY_MODAL_OPEN:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          createdKey: {
            raw_key: '',
            name: ''
          },
          generateModalOpen: action.payload
        }
      }
    case apiKeysTypes.SET_CREATED_KEY:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          createdKey: {
            raw_key: action.payload.raw_key,
            name: action.payload.name
          },
          generateModalOpen: false
        }
      }
    case apiKeysTypes.ADD_CREATED_KEY:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          keys: [
            ...state.apiKeys.keys,
            {
              active: action.payload.active,
              application_name: action.payload.application_name,
              created_at: action.payload.created_at,
              first_four: action.payload.first_four,
              id: action.payload.id,
              key: action.payload.key,
              name: action.payload.name
            }
          ]
        }
      }
    case apiKeysTypes.PENDING_REVOKE_API_KEY:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          isRevoking: true
        }
      }
    case apiKeysTypes.COMPLETED_REVOKE_API_KEY:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          isRevoking: false
        }
      }
    case apiKeysTypes.SUCCESSFUL_REVOKE_API_KEY: {
      const index = state.apiKeys.keys.findIndex(
        key => key.id === action.payload.id
      )
      const updatedKey = { ...state.apiKeys.keys[index], active: false }

      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          keys: replaceAtIndex(state.apiKeys.keys, index, updatedKey)
        }
      }
    }
    case apiKeysTypes.RESET_API_KEY:
      return {
        ...state,
        apiKeys: {
          ...initialState.apiKeys
        }
      }
    default:
      return state
  }
}

export default account
